import posthog from 'posthog-js'
const analyticsDisabled = localStorage.getItem('disableAnalytics') || false

if (!analyticsDisabled) {
	posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
		api_host: process.env.VUE_APP_POSTHOG_HOST,
		disable_session_recording: true,
		autocapture: false,
		loaded() {
			const userUuid = getUserUuid()
			getDistinctId(userUuid).then(distinctId => {
				if (distinctId) {
					posthog.identify(distinctId)
				}
			})
		},
	})
}

function getUserUuid() {
	const userInfo = localStorage.getObject('userInfo')
	if (!userInfo || !userInfo.user_uuid) return null
	return userInfo.user_uuid
}

async function getDistinctId(id) {
	if (!id) return
	// UUID hex -> decimal in Uint8Array -> Uint8Array from digest -> Distinct ID in hex
	let stripped = id.replaceAll('-', '')
	if (!stripped) return null
	let decimalArray = []
	while (stripped.length > 0) {
		let byte = stripped.slice(0, 2)
		stripped = stripped.slice(2)
		decimalArray.push(parseInt(byte, 16))
	}
	const digest = await crypto.subtle.digest('SHA-256', new Uint8Array(decimalArray))
	return new Uint8Array(digest).reduce((hexDigest, byte) => {
		return hexDigest + byte.toString(16).padStart(2, '0')
	}, '')
}

function capture(eventName, eventOptions = {}) {
	const properties = {
		source: 'account',
		is_paid_event: false,
	}
	const options = Object.assign(properties, eventOptions)
	posthog.capture(eventName, options)
	if (process.env !== 'production') console.log('📈', eventName, options)
}

export default class Analytics {
	constructor(defaults = {}) {
		this.defaults = defaults
	}

	static capture(eventName, eventOptions) {
		if (analyticsDisabled) return
		capture(eventName, eventOptions)
	}

	identify(id) {
		if (analyticsDisabled) return
		getDistinctId(id).then(distinctId => {
			if (distinctId) {
				posthog.identify(distinctId)
			}
		})
	}

	reset() {
		if (analyticsDisabled) return
		posthog.reset(true)
	}

	capture(eventName, eventOptions) {
		capture.call(this, eventName, Object.assign({}, this.defaults, eventOptions))
	}
}
